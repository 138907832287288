.title {
    color: #442563;
    font-size: 30px;
    font-family: Albert Sans;
    font-weight: 700;
    word-wrap: break-word;
}
.text-normal {
    color: #442563;
    font-size: 16px;
    font-family: Albert Sans;
    font-weight: 400;
    line-height: 30px;
    word-wrap: break-word;
}
.logo {
    width: 200px;
    object-fit: contain;
}
.container {
    padding: 2% 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.mb-8 {
    margin-bottom: 80px;
}

.mb-5{
    margin-bottom: 50px;
}

.btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.img-btn {
    width: 180px;
    object-fit: contain;
    margin-right: 10px;
    margin-bottom: 10px;
}

a{
    text-decoration: none;
}

.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    font-family: Albert Sans;
    font-weight: 600;
    padding: 18px 0;
    word-wrap: break-word;
    background-color: #442563;
    box-shadow: 0px 0px 20px rgba(108.38, 0, 47.12, 0.10);
    border-radius: 10px;
    max-width: 370px;
    width: 100%;
}

.fixed{
    position: fixed;
    width: 100%;
    bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}